.main-page-container {
    background-color:rgb(180, 180, 180);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.middle-container {
    background-color: rgb(223, 223, 223);
    width: 80%;
    height: auto;
    padding:50px;
}

.center-flex-column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.console-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.center-flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 25px;
    width: 100%;
}

.page-header {
    border: 2px solid black;
    border-radius: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 2px;
    padding-bottom: 2px;
    color: rgb(255, 255, 255);
    text-align: center;
}

#rules-header {
    background-color: rgb(158, 45, 66);
    margin-bottom: 5px;
}

#connection-header {
    background-color:rgb(72, 86, 218);
    margin-bottom: -10px;
}

#discord {
    margin-bottom: -10px;
}

#discord-subtitle {
    margin-bottom: -5px;
}

#join-here {
    line-height: 50px;
}

.discord-image {
    max-width: 40px;
    max-height: 40px;
    padding-left: 7px;
    transition-duration: 0.5s;
}

.discord-image:hover {
    transition-duration: 0.5s;
    filter:saturate(10);
}
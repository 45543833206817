.connection-details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 3px solid black;
    border-radius: 10px;
    background-color:rgb(206, 206, 206);
    max-width: 400px;
    height: auto;
    padding: 10px;
}

.edition-icon {
    max-height: 100px;
}

.instructions {
    margin-left: 10px;
    margin-right: 10px;
}

.connection-detail {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 20px;
}
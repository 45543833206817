.top-banner {
    height: 160px;
    background: linear-gradient(rgba(0, 0, 0, 0.251), rgba(0, 0, 0, 0.766)), url('../resources/mc-grass.png');
    background-repeat:repeat-x;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 20px 10px rgb(59, 59, 59);
    position: relative;
}
.space-force-logo {
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    flex-grow: 1;
    background-image: url('../resources/space-force-logo.png');
    background-repeat:no-repeat;
    background-size: contain;
    background-position: center;
    z-index: 5;
}